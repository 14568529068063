import { useCallback } from "react";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { useApi } from "@stordco/fe-components";

import { useNetworkId } from "../components/Network";

export type ProductSearchParams = {
  catalog_id?: string;
  sort?: "" | "sku" | "-sku" | "updated_at" | "-updated_at";
  type?: "" | "kit" | "item";
  search?: string;
  before?: string;
  after?: string;
  limit?: number;
  exclude_digital?: boolean;
};

export const useProductSearch = () => {
  const networkId = useNetworkId();
  const api = useApi();

  return useCallback(
    (params: ProductSearchParams) =>
      api.get<{
        data: Product[];
        metadata: {
          after: string | null;
          before: string | null;
          limit: number;
          total_count: number;
          total_count_cap_exceeded: boolean;
        };
      }>({
        url: "/bff/v1/products",
        params: {
          network_id: networkId,
          ...params,
        },
      }),
    [networkId, api],
  );
};

export function useCachedProductSearch() {
  const productSearch = useProductSearch();

  const queryClient = useQueryClient();

  return useCallback(
    (params: ProductSearchParams) =>
      queryClient.ensureQueryData({
        queryKey: ["Products", params],
        queryFn: () => productSearch(params),
        staleTime: 1000 * 60 * 5,
      }),
    [queryClient, productSearch],
  );
}

export function useProducts(
  params: ProductSearchParams,
  options?: { keepPreviousData?: true },
) {
  const productSearch = useProductSearch();

  return useQuery({
    queryKey: ["Products", params],
    queryFn: () => productSearch(params),
    ...(options?.keepPreviousData && {
      placeholderData: keepPreviousData,
    }),
  });
}

export interface Product {
  id: string;
  type: "kit" | "item";
  sku: string;
  name: string;
  primary_unit: string | null;
  description: string | null;
  upc: string | null;
  updated_at: string;
  image_urls: string[];
}
