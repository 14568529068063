import { useHotkeys } from "react-hotkeys-hook";

import { useDatadogRUM, useConfig, useIsAdmin } from "@stordco/fe-components";

export const AdminKeyboardShortcutController = () => {
  const isAdmin = useIsAdmin();
  const datadogRum = useDatadogRUM();
  const { ADMIN_HOST } = useConfig();

  useHotkeys("mod+shift+o", () => {
    if (isAdmin) {
      datadogRum?.addAction("hotkey:open_admin");
      window.location.href = ADMIN_HOST;
    }
  });

  return null;
};
