import { useAuthPermissions } from "./cloudApi/cloudComponents";
import { useIsAdmin } from "../../../../packages/components/src/hooks/useIsAdmin";
import { useNetwork } from "../components/Network";

export function useHasCloudPermissions(
  requiredPermissions: PermissionString[],
  method: "some" | "every" = "every",
):
  | {
      status: "error";
    }
  | {
      status: "pending";
    }
  | {
      status: "success";
      result: boolean;
    } {
  const isAdmin = useIsAdmin();
  const network = useNetwork();

  const permissionsQuery = useAuthPermissions<{
    data: {
      permissions: CloudPermissionsData;
    };
  }>(
    {
      pathParams: {
        app: "cloud",
        organization: network.tenant_id,
      },
    },
    {
      enabled: !isAdmin,
    },
  );

  if (isAdmin) {
    return {
      status: "success",
      result: true,
    };
  }

  if (permissionsQuery.isError) {
    return {
      status: "error",
    };
  }

  if (permissionsQuery.isPending) {
    return {
      status: "pending",
    };
  }

  return {
    status: "success",
    result: requiredPermissions[method]((requiredPermission) => {
      const [target, permission] = requiredPermission.split(".") as [
        keyof CloudPermissions,
        any,
      ];

      return (
        permissionsQuery.data.data.permissions[target]?.includes(permission) ??
        false
      );
    }),
  };
}

// Values from https://github.com/stordco/cloud-service/blob/main/priv/apps/cloud.yaml#L35-L67
interface CloudPermissions {
  activities: "read";
  insights: "read";
  api_keys: "read" | "write";
  organization_members: "read" | "write";
}

type PermissionString = {
  [T in keyof CloudPermissions]: `${T}.${CloudPermissions[T]}`;
}[keyof CloudPermissions];

type CloudPermissionsData = {
  [T in keyof CloudPermissions]?: Array<CloudPermissions[T]>;
};
