import { useCallback } from "react";

import { useAutomationNetworkConfigurationFlags } from "../../components/Network/useNetwork";

import type { ActionType, AutomationGroup } from "./types";

export function useHasAccessToAutomation() {
  const flags = useAutomationNetworkConfigurationFlags();

  const hasAccessToAutomationGroup = useCallback(
    (automationGroup: AutomationGroup) => {
      // TODO: check if we need to add more flags here
      switch (automationGroup) {
        case "order_enrichment":
          return flags.enable_order_enrichment_automations;
        case "order_routing":
          return (
            flags.enable_orchestration || flags.enable_force_orders_automations
          );
        case "order_modification":
          return flags.order_modification_automations;
        case "carrier_selection":
          return (
            flags.enable_automations_cs_bdot ||
            flags.enable_automations_cs_ship_option ||
            flags.enable_automations_cs_service_method ||
            flags.enable_third_party_billing_automations ||
            flags.desired_delivery_date
          );
        case "backorder_strategy":
          return flags.enable_backorder_automations;
        case "hold_order":
          return flags.hold_order_automations_ui;
        case "fulfillment_consolidation":
          return flags.consolidate_fulfillments;
      }
    },
    [
      flags.enable_automations_cs_bdot,
      flags.enable_automations_cs_service_method,
      flags.enable_automations_cs_ship_option,
      flags.enable_backorder_automations,
      flags.enable_force_orders_automations,
      flags.enable_orchestration,
      flags.enable_order_enrichment_automations,
      flags.enable_third_party_billing_automations,
      flags.hold_order_automations_ui,
      flags.order_modification_automations,
      flags.desired_delivery_date,
      flags.consolidate_fulfillments,
    ],
  );

  const hasAccessToActionType = useCallback(
    (actionType: ActionType) => {
      switch (actionType) {
        case "bdot":
          return flags.enable_automations_cs_bdot;
        case "ship_option":
          return flags.enable_automations_cs_ship_option;
        case "desired_delivery_date":
          return flags.desired_delivery_date;
        case "service_method":
          return flags.enable_automations_cs_service_method;
        case "third_party_billing":
          return flags.enable_third_party_billing_automations;
        case "ship_full_closest":
          return flags.enable_orchestration;
        case "ship_full_cheapest":
          return flags.enable_orchestration && flags.enable_ship_full_cheapest;
        case "force_facility":
          return flags.enable_force_orders_automations;
        case "complete_lines_only":
        case "no_split":
          return flags.enable_orchestration;
        default:
          return true;
      }
    },
    [
      flags.enable_automations_cs_bdot,
      flags.enable_automations_cs_service_method,
      flags.enable_automations_cs_ship_option,
      flags.enable_force_orders_automations,
      flags.enable_orchestration,
      flags.enable_ship_full_cheapest,
      flags.enable_third_party_billing_automations,
      flags.desired_delivery_date,
    ],
  );

  return { hasAccessToAutomationGroup, hasAccessToActionType };
}
