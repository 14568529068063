import { useLocation, useParams } from "react-router-dom";

import RedirectUser from "./RedirectUser";

export const ConnectionRedirects = () => {
  const location = useLocation();
  const { connectionName } = useParams<{ connectionName: string }>();

  const params = new URLSearchParams(location.search);
  const nonce = params.get("nonce");

  const intendedUrl = `/connections/explore?connect=${connectionName}&nonce=${nonce}`;

  return <RedirectUser intendedUrl={intendedUrl} />;
};
