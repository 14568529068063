import { Navigate } from "react-router-dom";

import {
  useIdentity,
  useIsAdmin,
  useConfig,
  PageLoader,
  NotFoundErrorPage,
} from "@stordco/fe-components";

/**
 * @param intendedUrl - if the user passes all the checks, this is the URL we want to access
 */
const RedirectUser = ({ intendedUrl = "" }: { intendedUrl?: string }) => {
  const user = useIdentity();
  const isAdmin = useIsAdmin();
  const { ADMIN_HOST } = useConfig();

  if (isAdmin) {
    window.location.assign(ADMIN_HOST);

    return <PageLoader />;
  }

  const firstNetworkId = (() => {
    for (const organization of user.organizations) {
      for (const app of organization.apps!) {
        if (app.alias === "oms") {
          for (const realm of app.realms!) {
            return realm.resource_id;
          }
        }
      }
    }
  })();

  if (!firstNetworkId) {
    return <NotFoundErrorPage outsideNavigation />;
  }

  return (
    <Navigate to={`/distribution_networks/${firstNetworkId}` + intendedUrl} />
  );
};

export default RedirectUser;
