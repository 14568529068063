import {
  Badge,
  Flex,
  Grid,
  IconButton,
  LinkOverlay,
  Text,
} from "@chakra-ui/react";
import { faPlug } from "@fortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router-dom";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";

import {
  EmptyData,
  EmptyDataDescription,
  FaIcon,
  PageLoader,
  Card,
} from "@stordco/fe-components";

import { useNetworkId } from "../../components/Network";

import { useConnections } from "./ConnectionManagement/common/commonQueries";
import { ConnectionLogo } from "./ConnectionManagement/common/ConnectionLogo";

export default function InstalledConnections() {
  const { data: connections, error, isError, isPending } = useConnections();

  const navigate = useNavigate();
  const networkId = useNetworkId();

  if (isError) {
    throw error;
  }

  if (isPending) {
    return <PageLoader />;
  }

  if (!connections.length) {
    return (
      <EmptyData icon={faPlug} title="It seems a piece is missing">
        <EmptyDataDescription>
          Your account is not yet connected to other systems. Once those
          connections exist, they will show up here.
        </EmptyDataDescription>
      </EmptyData>
    );
  }

  const sortedAndFilteredConnections = connections.sort((a, b) => {
    // Sort aggregator type before others
    if (a.source === "pipe17") {
      return -1; // `pipe17` comes first
    }
    if (b.source === "pipe17") {
      return 1; // `pipe17` comes first
    }

    if (a.source === "logic_broker") {
      return -1; // `logic_broker` comes second
    }
    if (b.source === "logic_broker") {
      return 1; // `logic_broker` comes second
    }

    return a.display_name.localeCompare(b.display_name);
  });

  return (
    <Grid
      rowGap={4}
      columnGap={6}
      templateColumns={{ base: "1fr", md: "1fr 1fr" }}
    >
      {sortedAndFilteredConnections.map((connection) => (
        <Card.LinkBox key={connection.id}>
          <Card.PaddedContent>
            <Card.HeaderWithCenteredRightElement
              leftElement={
                <ConnectionLogo boxSize={10} source={connection.source} />
              }
              title={
                connection.source === "pipe17" ||
                connection.source === "logic_broker" ? (
                  <Flex alignItems="center">
                    <Text textStyle="subtitle" mr={2}>
                      {connection.display_name}
                    </Text>
                    <Badge colorScheme="gray">Visible only to Stord</Badge>
                  </Flex>
                ) : (
                  connection.display_name
                )
              }
              rightElement={
                <LinkOverlay
                  as={IconButton}
                  variant="unstyled"
                  onClick={() =>
                    navigate(
                      `/distribution_networks/${networkId}/connections/${connection.id}`,
                    )
                  }
                >
                  <FaIcon icon={faAngleRight} />
                </LinkOverlay>
              }
            />
          </Card.PaddedContent>
        </Card.LinkBox>
      ))}
    </Grid>
  );
}
