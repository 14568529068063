import { useEffect } from "react";
import userflow from "userflow.js";
import { useNavigate } from "react-router-dom";
import { useConfig } from "@stordco/fe-components";

import { useNetwork } from "./Network";
import { useUser } from "../../../../packages/components/src/hooks/useUser";
import { useNetworkRole } from "../hooks/useNetworkRole";

export function UserflowInitializer() {
  const { USERFLOW_CLIENT_KEY } = useConfig();
  const user = useUser();
  const navigate = useNavigate();
  const network = useNetwork();
  const role = useNetworkRole();

  useEffect(() => {
    if (!USERFLOW_CLIENT_KEY) {
      return;
    }

    userflow.init(USERFLOW_CLIENT_KEY);
    userflow.setResourceCenterLauncherHidden(true);
    userflow.setCustomNavigate((url) => {
      navigate(url);
    });

    userflow.identify(user.id!, {
      viewport_width: window.innerWidth,
      viewport_height: window.innerHeight,
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      company: network.company.name,
      version: "v2",
      networkName: network.name,
      networkId: network.network_id,
      role,
    });
  }, [USERFLOW_CLIENT_KEY, navigate, user, network, role]);

  return null;
}
