import type { NavigationProviderProps } from "@stordco/fe-components";
import { useAuthentication, useConfig, useUser } from "@stordco/fe-components";

import { useNetworkId } from "../../Network";

const getOs = () => {
  const os = ["Windows", "Linux", "Mac"]; // add your OS values
  return os.find((v) => navigator.appVersion.indexOf(v) >= 0);
};

export const useUserMenu = (): NavigationProviderProps["userMenu"] => {
  const os = getOs();
  const { ADMIN_HOST } = useConfig();
  const client = useAuthentication();
  const networkId = useNetworkId();

  const user = useUser();

  let isOmsAdmin = false;
  const omsRealmOptions: { label: string; value: string; href: string }[] = [];

  for (const org of user.organizations) {
    for (const app of org.apps!) {
      if (app.alias === "oms_admin") {
        isOmsAdmin = true;
      }
      for (const realm of app.realms) {
        if (app.alias === "oms") {
          const isV1 = realm.full_resource_name.includes("v1_networks");
          if (isV1) {
            const targetOrigin = window.location.origin.includes("staging")
              ? "https://staging.v1.shipper.stord.com"
              : "https://v1.shipper.stord.com";

            omsRealmOptions.push({
              value: realm.resource_id,
              label: `${realm.name}`,
              href: `${targetOrigin}/distribution_networks/${realm.resource_id}`,
            });
          } else {
            omsRealmOptions.push({
              value: realm.resource_id,
              label: `${realm.name}`,
              href: `/distribution_networks/${realm.resource_id}`,
            });
          }
        }
      }
    }
  }

  const onLogout = () => {
    client.logout({ returnTo: window.location.origin });
  };

  return {
    showOperations: isOmsAdmin,
    selectedAccount: networkId,
    userDisplayName: `${user.first_name} ${user.last_name}`,
    operationsHref: ADMIN_HOST,
    operationsCommand: `${os === "Mac" ? "⌘" : "Ctrl"}⇧O`,
    onLogout,
    accountOptions: omsRealmOptions.length > 1 ? omsRealmOptions : [],
  };
};
